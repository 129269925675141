import React from "react";
import "./AppError.scss";
import { Logo } from "../Logo/Logo";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

type AppErrorProps = {
  error: any;
  resetErrorBoundary: () => void;
};

export const AppError = ({ error = {}, resetErrorBoundary }: AppErrorProps) => {
  const navigate = useNavigate();

  // const getBrowserData = () => {
  //   const currentUrl = window.location.href;
  //   const localStorageData = { ...localStorage };
  //   return {
  //     url: currentUrl,
  //     localStorageData,
  //   };
  // };

  const clearStorageAndReturn = () => {
    localStorage.clear();
    resetErrorBoundary();
    navigate("/");
  };

  // useEffect(() => {
  //   const { url, localStorageData } = getBrowserData();
  // }, [error]);

  return (
    <div className="app-error">
      <Logo
        size={100}
        style={{
          zIndex: 200,
          position: "relative",
          display: "block",
        }}
      />
      <h3>An error occurred :(</h3>
      <Button
        variant="contained"
        style={{
          textTransform: "none",
          borderRadius: "22px",
          minWidth: "266px",
        }}
        onClick={clearStorageAndReturn}
      >
        Take me back home
      </Button>
    </div>
  );
};
