export namespace DateUtils {
  export const formatDate = (inputDate: string): string => {
    const date = new Date(inputDate);
    if (isNaN(date.getTime())) {
      return "";
    }

    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };
    const formattedDate: string = date.toLocaleDateString(undefined, options);
    const [month, day, year]: string[] = formattedDate.split("/");
    return `${month}-${day}-${year}`;
  };

  export const getTimeAgo = (date: Date): string => {
    const now = new Date();
    const timeDifference = now.getTime() - date.getTime();

    const minutesAgo = Math.floor(timeDifference / (1000 * 60));

    if (minutesAgo < 1) {
      return "just now";
    } else if (minutesAgo < 60) {
      return `${minutesAgo} minute${minutesAgo === 1 ? "" : "s"} ago`;
    }

    const hoursAgo = Math.floor(timeDifference / (1000 * 60 * 60));

    if (hoursAgo < 24) {
      return `${hoursAgo} hour${hoursAgo === 1 ? "" : "s"} ago`;
    }

    const daysAgo = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    if (daysAgo < 30) {
      return `${daysAgo} day${daysAgo === 1 ? "" : "s"} ago`;
    }

    const monthsAgo = Math.floor(daysAgo / 30);
    return `${monthsAgo} month${monthsAgo === 1 ? "" : "s"} ago`;
  };
}
