import { atom } from "recoil";
import { ReactNode } from "react";
import { AppDefaultTexts } from "@appTypes";

export const userState = atom<{
  displayName: string;
  avatar: string;
  email: string;
  id: string;
}>({
  key: "userState",
  default: undefined,
});

export const toastState = atom<{
  timestamp: number;
  toast: ReactNode;
  success: boolean;
}>({
  key: "toastState",
  default: {
    timestamp: Date.now(),
    toast: null,
    success: true,
  },
});

export const appDefaultTextsState = atom<AppDefaultTexts>({
  key: "appDefaultTextsState",
  default: {},
});
