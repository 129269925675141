export namespace CloudinaryUtils {
  export const videoUrlToSubSceneUrl = (
    videoUrl: string,
    startOffset: number,
    endOffset: number
  ) => videoUrl.replace("upload/", `upload/so_${startOffset},eo_${endOffset}/`);

  export const subSceneUrlToVideoUrl = (url: string): string => {
    const pattern = /upload\/so_\d+(\.\d+)?,eo_\d+(\.\d+)?\//;
    return url.replace(pattern, "upload/");
  };

  export const resizeVideo = (
    url: string,
    width: number,
    height: number
  ): string => {
    if (!url) return url;
    const splitUrl = url.split("/");
    let cellIndex = splitUrl.findIndex( item => item.indexOf("b_blurred") != -1);
    if (cellIndex > -1) {
      splitUrl[cellIndex] = `b_blurred:400:0,c_pad,h_${height},w_${width}`;
    } else {
      const index = splitUrl.indexOf( "fetch");
      splitUrl.splice(index+1, 0, `b_blurred:400:0,c_pad,h_${height},w_${width}`);
    }

    return splitUrl.join("/");
  };
}
