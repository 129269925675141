import React, { useEffect, useState } from "react";
import { Alert, CssBaseline, Snackbar, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import "./App.scss";
import { Navigate, Route, Routes, useSearchParams } from "react-router-dom";
import { AppError, AppLoader } from "@components";
import { Library } from "@tabs";
import { useRecoilState, useRecoilValue } from "recoil";
import { appDefaultTextsState, toastState } from "./App.state";
import { ErrorBoundary } from "react-error-boundary";
import { theme } from "./theme";
import { getAppDefaultTexts } from "@services";

const appTheme = createTheme(theme);

const App = () => {
  // const [user, setUser] = useRecoilState(userState);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const { toast, timestamp, success } = useRecoilValue(toastState);
  const [toastOpen, setToastOpen] = useState(false);
  const [defaultTexts, setDefaultTexts] = useRecoilState(appDefaultTextsState);

  const loadApp = async () => {
    const appsDefaultTexts = await getAppDefaultTexts();
    setDefaultTexts(appsDefaultTexts);
    // const user = await login();
    // if (!user) {
    //   throw new Error("Failed to fetch User");
    // }
    // setUser(user as any);
    if (searchParams.has("e")) {
      searchParams.delete("e");
      setSearchParams(searchParams);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setToastOpen(!!toast);
  }, [timestamp, toast]);

  useEffect(() => {
    loadApp();
  }, []);

  return (
    <ThemeProvider theme={appTheme}>
      <CssBaseline>
        <ErrorBoundary FallbackComponent={AppError}>
          {isLoading ? (
            <AppLoader />
          ) : (
            <div className="maverick">
              <div className="maverick__content">
                <Routes>
                  <Route path="library" element={<Library />}></Route>
                  <Route path="*" element={<Navigate to="library" replace />} />
                </Routes>
                <Snackbar
                  anchorOrigin={{ vertical: "top", horizontal: "center" }}
                  key={timestamp}
                  open={toastOpen}
                  autoHideDuration={3000}
                  onClose={(event, reason) => {
                    if (reason !== "clickaway") {
                      setToastOpen(false);
                    }
                  }}
                >
                  <Alert
                    onClose={() => setToastOpen(false)}
                    severity={success ? "success" : "error"}
                    sx={{ width: "100%" }}
                  >
                    {toast}
                  </Alert>
                </Snackbar>
              </div>
            </div>
          )}
        </ErrorBoundary>
      </CssBaseline>
    </ThemeProvider>
  );
};

export default App;
