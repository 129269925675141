import { Banner, Logo } from "@components";
import SocialLinks from "@components/SocialLinks/SocialLinks";
import { useIsMobile } from "@hooks";
import React from "react";

interface LibraryHeaderProps {
  onClick: () => void;
}

const headerLayout = process.env.REACT_APP_HEADER;
const logoSizeMobile = Number(process.env.REACT_APP_LOGO_SIZE_MOBILE);
const logoSizeWeb = Number(process.env.REACT_APP_LOGO_SIZE_WEB);

const LibraryHeader = (props: LibraryHeaderProps) => {
  const isMobile = useIsMobile();
  const banner = headerLayout === "banner" && isMobile;

  return banner ? (
    <Banner size={"100%"} />
  ) : (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
      onClick={props.onClick}
    >
      <Logo
        size={isMobile ? logoSizeMobile : logoSizeWeb}
        style={{ padding: "20px", paddingRight: "20px" }}
      />
      {isMobile && (
        <SocialLinks
          style={{ paddingRight: "10px" }}
          instagramLink={process.env.REACT_APP_INSTAGRAM_LINK}
          facebookLink={process.env.REACT_APP_FACEBOOK_LINK}
        />
      )}
    </div>
  );
};

export default LibraryHeader;
