import React from "react";
import { styled, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    fontSize: 12,
    color: "inherit",
    border: theme.borders[1],
    boxShadow: "0px 2px 14px rgba(148, 148, 148, 0.3)",
  },
}));
