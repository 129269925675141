import React, { ReactNode } from "react";
import { useSetRecoilState } from "recoil";
import { toastState } from "../App.state";

export const useToastsManager = () => {
  const setToast = useSetRecoilState(toastState);

  const custom = (children: ReactNode, success = true) =>
    setToast({
      timestamp: Date.now(),
      toast: children,
      success,
    });

  const success = (title: string, subtitle?: string) =>
    custom(<div className="app-toast app-toast--success">{title}</div>);

  return { custom, success };
};
