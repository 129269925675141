import { cloneDeep, forEach, isEmpty } from "lodash";
import { LRUCache } from "lru-cache";

const cacheOptions = {
  max: 5,
};

export namespace GeneralUtils {
  const cache = new LRUCache(cacheOptions);

  export const copyToClipboard = (text: string): Promise<void> => {
    return navigator.clipboard.writeText(text);
  };

  export const sortOptionListByLabel = <
    T extends {
      label: string;
    }
  >(
    list: T[]
  ) => {
    list.sort((a, b) => a.label.localeCompare(b.label));
    return list;
  };

  export const firstCapitalLetter = (str: string): string => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  export const removeEmptyFields = (obj: object): object => {
    const result = cloneDeep(obj);
    forEach(result, (value, key) => {
      if (isEmpty(value)) {
        delete result[key];
      }
    });
    return result;
  };

  export const downloadFileToBlob = async (url: string) => {
    const response = await fetch(url);
    const blob = await response.blob();
    return blob;
  };

  export const getFileExtension = (url: string) => {
    const lastIndex = url.lastIndexOf(".");
    const extension = url.slice(lastIndex + 1);
    return extension;
  };

  export const downloadFile = async (id: string, url: string) => {
    try {
      const cachedFile = cache.get(url);
      if (cachedFile) {
        return cachedFile;
      }
      const blob = await downloadFileToBlob(url);
      const file = new File(
        [blob],
        `global-crisis-${id}.${getFileExtension(url)}`,
        {
          type: blob.type.includes("video") ? "video/mp4" : blob.type,
        }
      );
      cache.set(url, file);
      return file;
    } catch (e) {
      console.log(`failed to download to file`, e);
    }
  };

  export const downloadFiles = async (
    id: string,
    urls: string[]
  ): Promise<File[]> => {
    const promises = urls.map((url, index) => {
      return downloadFile(`${id}-${index}`, url);
    });
    const files = (await Promise.all(promises)).filter((file) => file);
    return files as File[];
  };

  export const downloadFileToHtmlElement = async (id: string, url: string) => {
    try {
      const blob = await downloadFileToBlob(url);
      const objectUrl = window.URL.createObjectURL(new Blob([blob]));
      const a = document.createElement("a");
      a.href = objectUrl;
      a.download = `global-crisis-${id}.${getFileExtension(url)}`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(objectUrl);
    } catch (e) {
      console.log(`failed to download`, e);
    }
  };

  export const downloadFilesToHtmlElement = async (
    id: string,
    urls: string[]
  ) => {
    const promises = urls.map((url, index) => {
      downloadFileToHtmlElement(`${id}-${index}`, url);
    });

    await Promise.all(promises);
  };

  export const checkIphone = () => {
    const u = navigator.userAgent;
    return !!u.match(/iPhone/i);
  };
  export const checkAndroid = () => {
    const u = navigator.userAgent;
    return !!u.match(/Android/i);
  };
  export const checkIpad = () => {
    const u = navigator.userAgent;
    return !!u.match(/iPad/i);
  };
  export const checkMobile = () => {
    const u = navigator.userAgent;
    return !!u.match(/Android/i) || !!u.match(/iPhone/i);
  };

  export const extractEmbeddedReplyUrl = (
    url: string,
    textToInject: string
  ) => {
    const { hostname, pathname } = new URL(url);
    if (hostname.includes("twitter")) {
      const twitterSearchText = "status/";
      const tweetIdStartIndex = pathname.lastIndexOf(twitterSearchText);
      if (tweetIdStartIndex < 0) {
        return url;
      }
      const tweetId = pathname.slice(
        twitterSearchText.length + tweetIdStartIndex
      );
      return `https://twitter.com/intent/tweet?in_reply_to=${tweetId}&text=${encodeURI(
        textToInject
      )}`;
    }
    return url;
  };

  export const extractSocialNetworkName = (url: string) => {
    const { hostname } = new URL(url);
    if (hostname.includes("tiktok")) {
      return "TikTok";
    }
    if (hostname.includes("instagram")) {
      return "Instagram";
    }
    if (hostname.includes("facebook")) {
      return "Facebook";
    }
    if (hostname.includes("twitter")) {
      return "Twitter";
    }
    return url;
  };
}
