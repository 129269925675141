import React, { useEffect } from "react";

import { Zoom, useScrollTrigger } from "@mui/material";

interface HideOnScrollProps {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  element?: HTMLDivElement | null;
  children: React.ReactElement;
}

const useHideOnScroll = (options) => {
  const { threshold, scroller } = options;

  const scrollRef = React.useRef();
  const [hide, setHide] = React.useState(false);

  const handleScroll = React.useCallback(() => {
    const scrollY = getScrollY(scroller || window);
    const prevScrollY = scrollRef.current;
    scrollRef.current = scrollY;

    if (scrollY === 0) {
      setHide(false);
      return;
    }
    if (
      scrollY > prevScrollY! &&
      scrollY > (threshold != null ? threshold : 100)
    ) {
      setHide(true);
      return;
    }
  }, [scroller, threshold]);

  useEffect(() => {
    if (scroller) {
      scroller.addEventListener("scroll", handleScroll);
    }
  }, [scroller]);

  useEffect(() => {
    return () => {
      if (scroller) {
        scroller.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  return hide;
};

function getScrollY(scroller) {
  if (!scroller) return;
  return scroller.pageYOffset !== undefined
    ? scroller.pageYOffset
    : scroller.scrollTop !== undefined
    ? scroller.scrollTop
    : (document.documentElement || document.body.parentNode || document.body)
        .scrollTop;
}

const HideOnScroll = (props: HideOnScrollProps) => {
  const { children, element } = props;
  const hide = useHideOnScroll({ scroller: element, threshold: 0 });
  return (
    <Zoom appear={false} in={!hide}>
      <div style={{ display: "flex", height: !hide ? "100%" : "0px" }}>
        {children}
      </div>
    </Zoom>
  );
};

export default HideOnScroll;
