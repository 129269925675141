import {
  ConceptType,
  Creative,
  Language,
  PostText,
  ScriptText,
  Tag,
} from "../types";
import { db } from "./firebase";
import {
  collection,
  getDocs,
  where,
  limit,
  orderBy,
  query,
  QueryConstraint,
  DocumentSnapshot,
  startAfter,
} from "firebase/firestore";

export interface CountedCreativesResponse {
  cursor: DocumentSnapshot<unknown> | null;
  items: Creative[];
}

export interface PaginationPayload {
  cursor?: DocumentSnapshot<unknown>;
  itemsPerPage: number;
  conceptTypeId: string;
  filters: {
    tags?: string[];
    language?: string;
  };
}

const computeAssetUrl = (type: string, url: string) => {
  return `https://res.cloudinary.com/dccm0o3rq/${type === 'video'? 'video' : 'image'}/fetch/q_auto:eco/${encodeURIComponent(url)}`;
};

export const getLanguagesList = async (): Promise<Language[]> => {
  const q = query(collection(db, "languages"), orderBy("name", "asc"));
  const documentSnapshots = await getDocs(q);
  return documentSnapshots.docs.map((doc) => {
    const docData = doc.data();
    return {
      id: doc.id,
      name: docData["name"],
    };
  });
};

export const getTagsList = async (): Promise<Tag[]> => {
  const q = query(collection(db, "tags"));
  const documentSnapshots = await getDocs(q);
  return documentSnapshots.docs.map((doc) => {
    const docData = doc.data();
    return {
      id: doc.id,
      displayName: docData["displayName"],
    };
  });
};

export const getConceptTypesList = async (): Promise<ConceptType[]> => {
  const q = query(collection(db, "concept_types"), orderBy("order", "asc"));
  const documentSnapshots = await getDocs(q);
  const conceptItems = documentSnapshots.docs.map((doc) => {
    const docData = doc.data();
    return {
      id: doc.id,
      title: docData["title"],
      hidden: docData["hidden"],
      htmlSubtitle: docData["htmlSubtitle"],
      isSensitive: docData["isSensitive"],
    };
  });
  return conceptItems.filter((item) => !item.hidden);
};

export const paginateLibraryItems = async (
  payload: PaginationPayload
): Promise<CountedCreativesResponse> => {
  const constraints: QueryConstraint[] = [];
  constraints.push(orderBy("createdAt", "desc"));
  constraints.push(where(`conceptType.${payload.conceptTypeId}`, "==", true) as any);

  if (payload.cursor) {
    constraints.push(startAfter(payload.cursor) as any);
  }
  if (payload.filters.tags && payload.filters.tags.length > 0) {
    constraints.push(
      where("tags", "array-contains-any", payload.filters.tags) as any
    );
  }
  if (payload.filters.language && payload.filters.language.length > 0) {
    constraints.push(
      where("language", "array-contains", payload.filters.language) as any
    );
  }
  const paginationQuery = query(
    collection(db, "assets_v2"),
    ...constraints,
    where("pinned", "==", false),
    limit(payload.itemsPerPage)
  );
  const pinnedQuery = query(
    collection(db, "assets_v2"),
    ...constraints,
    where("pinned", "==", true)
  );
  const [documentSnapshots, pinnedDocumentSnapshots] = await Promise.all([
    getDocs(paginationQuery),
    payload.cursor ? Promise.resolve(null) : getDocs(pinnedQuery),
  ]);
  const newCursor =
    documentSnapshots.docs.length > 0
      ? documentSnapshots.docs[documentSnapshots.docs.length - 1]
      : null;

  const allDocs: any[] = [];
  pinnedDocumentSnapshots &&
    pinnedDocumentSnapshots.docs.length > 0 &&
    allDocs.push(...pinnedDocumentSnapshots.docs);

  documentSnapshots.docs.length > 0 && allDocs.push(...documentSnapshots.docs);
  const assets = allDocs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
  }));

  const items = assets.map((asset): Creative => {
    const postText: PostText[] =
      (Array.isArray(asset["postText"]) && asset["postText"]) || [];
    const scriptText: ScriptText[] =
      (Array.isArray(asset["scriptText"]) && asset["scriptText"]) || [];
    const assetUrls = asset["urls"]?.map((url: string) => {
      if (asset["type"] === "embedded") return url;
      return computeAssetUrl(asset["type"], url);
    });
    return {
      id: asset.id,
      assetId: asset["assetId"],
      shareUrl: `${process.env.REACT_APP_DOMAIN_URL}/asset?id=${asset["assetId"]}&language=${payload.filters.language}`,
      type: asset["type"],
      urls: assetUrls,
      language: asset["language"],
      tags: asset["tags"],
      source: asset["source"],
      postText,
      title: asset["title"],
      pinned: asset["pinned"],
      conceptType: asset["conceptType"],
      scriptText,
      createdAt: asset["createdAt"].seconds * 1000,
    };
  });
  return {
    items: items,
    cursor: newCursor,
  };
};
