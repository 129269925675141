import React, { CSSProperties } from "react";

type CircleProps = {
  width: number;
  backgroundColor: string;
  children?: React.ReactNode;
  style?: CSSProperties;
} & React.HTMLProps<HTMLDivElement>;

export const Circle = ({
  width,
  backgroundColor,
  children,
  style,
  ...rest
}: CircleProps) => {
  return (
    <div
      {...rest}
      style={{
        width: `${width}px`,
        height: `${width}px`,
        borderRadius: "50%",
        backgroundColor,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: "bold",
        ...style,
      }}
    >
      {children}
    </div>
  );
};
