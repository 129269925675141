import { Grid, useTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
const CopyIcon = require("../../assets/copy.svg").default;
const RegenerateIcon = require("../../assets/regenerate.svg").default;

type ExpansionState = "unknown" | "enough-space" | "collapsed" | "expanded";

type CollapsibleTextProps = {
  numberOfLines: number;
  text: string;
  onClickRegenrateTextButton?: () => void;
  onClickCopyText?: () => void;
};

const CollapsibleText = ({
  numberOfLines,
  text,
  onClickCopyText,
  onClickRegenrateTextButton,
}: CollapsibleTextProps) => {
  const theme = useTheme();
  const [expansionState, setExpansionState] =
    useState<ExpansionState>("unknown");

  const elementRef = useRef(null);
  useEffect(() => {
    if (!elementRef.current) {
      return;
    }

    const { fontSize: fontSizeText, height: heightText } = getComputedStyle(
      elementRef.current
    );
    const fontSize = parseFloat(fontSizeText);
    const height = parseFloat(heightText);

    if (height > fontSize * numberOfLines) {
      setExpansionState("collapsed");
    } else {
      setExpansionState("enough-space");
    }
  }, [numberOfLines, text]);

  return (
    <Grid
      display="flex"
      alignItems="center"
      gap={0}
      style={{ border: "1px solid #E3E3E3" }}
      padding="10px"
    >
      {onClickRegenrateTextButton && (
        <img
          alt="regenerat"
          src={RegenerateIcon}
          style={{ paddingRight: "10px", cursor: "pointer" }}
          className="fa fa-refresh fa-lg"
          onClick={() => onClickRegenrateTextButton()}
        ></img>
      )}
      {onClickCopyText && (
        <img
          alt="copy"
          style={{ paddingRight: "10px", cursor: "pointer" }}
          src={CopyIcon}
          onClick={() => {
            onClickCopyText();
          }}
        />
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "1em",
          marginBottom: "1em",
        }}
      >
        <p
          style={{
            fontSize: "14px",
            opacity: expansionState === "unknown" ? 0 : 1,
            lineHeight: "1em",
            overflow: "hidden",
            height:
              expansionState === "collapsed" ? `${numberOfLines}em` : "auto",
            margin: 0,
          }}
          ref={elementRef}
        >
          {text}
        </p>
        {expansionState === "collapsed" ? (
          <span
            onClick={() => {
              setExpansionState("expanded");
            }}
            style={{
              alignSelf: "flex-end",
              fontWeight: "bold",
              cursor: "pointer",
              marginTop: "2px",
              color: theme.palette.action.selected,
            }}
          >
            read more
          </span>
        ) : null}
      </div>
    </Grid>
  );
};

export default CollapsibleText;
