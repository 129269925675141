import "./LibraryItemShareModal.scss";

import { Creative } from "@appTypes";
import { ShareButtons } from "@components/ShareButtons/ShareButtons";
import { Box, Drawer } from "@mui/material";
import React from "react";

type LibraryItemModalProps = {
  creative: Creative;
  isOpen: boolean;
  onClose: () => void;
};

const LibraryItemShareModal = (props: LibraryItemModalProps) => {
  const { creative, isOpen, onClose } = props;

  return (
    <Drawer anchor="bottom" open={isOpen} onClose={onClose}>
      <Box>
        <Box
          sx={{
            bgcolor: "dark",
            pl: 2,
            pr: 2,
          }}
        >
          <h3>Share</h3>
          <ShareButtons
            creative={creative}
            // Do we need to share the posts with some default text? Maybe postText[0]?
            text={""}
          />
        </Box>
      </Box>
    </Drawer>
  );
};

export default LibraryItemShareModal;
