import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "./Library.scss";
import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Select,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ConceptType, Creative, Language, Tag } from "@appTypes";
import {
  PaginationPayload,
  getConceptTypesList,
  getLanguagesList,
  getTagsList,
  paginateLibraryItems,
} from "@services";
import LibraryGridItem from "./LibraryGridItem/LibraryGridItem";
import InfiniteScroll from "react-infinite-scroll-component";
import LibraryContentTypeFilter from "./LibraryConceptTypeFilter/LibraryConceptTypeFilter";
import parse from "html-react-parser";
import SensitiveContentDialog from "./SensitiveContentDialog/SensitiveContentDialog";
import LibraryHeader from "./LibraryHeader/LibraryHeader";
import { useIsMobile } from "@hooks";
import { appDefaultTextsState } from "../../App.state";
import { useRecoilState } from "recoil";
import HideOnScroll from "@components/HideOnScroll/HideOnScroll";
import { MomTooButton } from "@components";

export const Library = () => {
  const isMobile = useIsMobile();
  const [items, setItems] = useState<Creative[]>([]);
  const [tags, setTags] = useState<Tag[]>([]);
  const [languages, setLanguages] = useState<Language[]>([]);
  const [selectedLanguage, setSelectedLanguage] = useState<Language>();
  const [conceptTypes, setConceptTypes] = useState<ConceptType[]>([]);
  const [selectedConceptTypeId, setSelectedConceptTypeId] = useState<string>(
    undefined!
  );
  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);
  const [cursor, setCursor] = useState<any>();
  useState<any>();
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [hasNext, setHasNext] = useState(true);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const scrollWrapperRef = useRef<HTMLDivElement>(null);
  const [defaultTexts, setDefaultTexts] = useRecoilState(appDefaultTextsState);
  const loadPage = async () => {
    setIsLoadingPage(true);
    const [conceptTypesResponse, tags, languages] = await Promise.all([
      getConceptTypesList(),
      getTagsList(),
      getLanguagesList(),
    ]);
    setLanguages(languages);
    setSelectedLanguage(languages[0]);
    setConceptTypes(conceptTypesResponse);
    setSelectedConceptTypeId(conceptTypesResponse[0].id);
    setTags(tags);
    setIsLoadingPage(false);
  };

  const tagsOptions = useMemo(() => {
    return tags.map((tag) => ({
      label: tag.displayName,
      value: tag.id,
    }));
  }, [tags]);

  const languagesOptions = useMemo(() => {
    return languages.map((tag) => ({
      label: tag.name,
      value: tag.id,
    }));
  }, [languages]);

  useEffect(() => {
    if (scrollWrapperRef?.current) {
      scrollWrapperRef.current.scrollTo(0, 0);
    }
  }, [selectedConceptTypeId, selectedLanguage]);

  useEffect(() => {
    loadPage();
  }, []);

  // This is used so that we can use the cursor inside the retrieveItems callback,
  // but not pass it as a dependency so that it doesn't result in an infinite loop
  const cursorRef = useRef<PaginationPayload["cursor"]>();
  useEffect(() => {
    cursorRef.current = cursor;
  }, [cursor]);

  const retrieveItems = useCallback(
    async (resetItems = false) => {
      setIsLoadingPage(true);
      const itemsRes = await paginateLibraryItems({
        itemsPerPage: 10,
        cursor: resetItems ? undefined : cursorRef.current,
        conceptTypeId: selectedConceptTypeId,
        filters: {
          tags: selectedTags.map((tag) => tag.id),
          language: selectedLanguage?.id,
        },
      });
      setHasNext(itemsRes.items.length > 0);
      setCursor(itemsRes.cursor);
      const newItems = itemsRes.items;
      setItems((currItems) =>
        resetItems ? newItems : [...currItems, ...newItems]
      );
      setIsLoadingPage(false);
    },
    [selectedConceptTypeId, selectedTags, selectedLanguage]
  );

  const subtitleText = useMemo(() => {
    const selectedConcept = conceptTypes.find(
      (concept) => concept.id === selectedConceptTypeId
    );
    const defaultText = defaultTexts?.tabsDefaultHtmlSubtitle ? (
      parse(defaultTexts.tabsDefaultHtmlSubtitle)
    ) : (
      <></>
    );
    const selectedConceptText =
      selectedConcept?.htmlSubtitle && parse(selectedConcept?.htmlSubtitle);
    return selectedConceptText || defaultText;
  }, [selectedConceptTypeId, conceptTypes, defaultTexts]);

  useEffect(() => {
    if (!selectedConceptTypeId) {
      return;
    }

    retrieveItems(true);
  }, [retrieveItems, selectedConceptTypeId]);

  const handleHeaderClick = useCallback(() => {
    if (selectedConceptTypeId == conceptTypes[0].id) {
      scrollWrapperRef.current?.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      setSelectedConceptTypeId(conceptTypes[0].id);
    }
  }, [scrollWrapperRef, conceptTypes, selectedConceptTypeId]);

  return (
    <div className="library">
      <SensitiveContentDialog
        disableClose={conceptTypes.length === 1}
        goBack={() => {
          const conceptTypeIndex = conceptTypes.findIndex(
            (concept) => concept.id === selectedConceptTypeId
          );
          if (conceptTypes.length > 1) {
            if (conceptTypeIndex === 0) {
              setSelectedConceptTypeId(conceptTypes[1].id);
            } else {
              setSelectedConceptTypeId(conceptTypes[conceptTypeIndex - 1].id);
            }
          }
        }}
        conceptType={conceptTypes.find(
          (concept) => concept.id === selectedConceptTypeId
        )}
      />
      <div className={"library-header"}>
        <LibraryHeader onClick={handleHeaderClick} />
        {isMobile && (
          <HideOnScroll element={scrollWrapperRef.current}>
            <div
              style={{
                paddingLeft: "20px",
                paddingRight: "20px",
                paddingBottom: "20px",
                height: "100%",
              }}
            >
              {subtitleText}
            </div>
          </HideOnScroll>
        )}

        <LibraryContentTypeFilter
          value={selectedConceptTypeId}
          onChange={setSelectedConceptTypeId}
          values={conceptTypes}
        />
      </div>
      {!isMobile && (
        <div className="language-select-container">
          <MomTooButton />
        </div>
      )}
      {/* {languagesOptions && (
        <div className="language-select-container">
          <FormControl fullWidth size="small">
            <Select
              sx={{ fontSize: "small" }}
              value={selectedLanguage?.id || ""}
              displayEmpty
              onChange={(event) => {
                setSelectedLanguage(
                  languages.find(
                    (language) => language.id === event.target.value
                  )
                );
              }}
            >
              {languagesOptions.map((option) => (
                <MenuItem
                  sx={{ fontSize: "small" }}
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      )} */}
      <div
        ref={scrollWrapperRef}
        className="library__content"
        id="scrollableDiv"
        style={{
          overflow: "auto",
          display: "flex",
        }}
      >
        <InfiniteScroll
          dataLength={items.length}
          scrollableTarget="scrollableDiv"
          next={() => retrieveItems()}
          hasMore={hasNext}
          loader={
            <div
              style={{
                display: "flex",
                overflowY: "hidden",
                marginTop: "20px",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          }
          endMessage={
            <span
              style={{
                display: "flex",
                width: "100%",
                fontSize: "13px",
                marginTop: "20px",
                justifyContent: "center",
              }}
            >
              {items.length > 0
                ? "No more videos to load."
                : "No videos are available for the selected language"}
            </span>
          }
        >
          {!isMobile && (
            <Grid
              style={{
                backgroundColor: "white",
                paddingLeft: "20px",
                paddingRight: "20px",
                paddingBottom: "20px",
                paddingTop: "10px",
                textAlign: "center",
                lineHeight: "18px",
              }}
              key="grid-title"
              item
              xs={12}
              sm={12}
              md={12}
            >
              {subtitleText}
            </Grid>
          )}
          <Grid
            container
            spacing={isSmall ? 2 : 2}
            paddingLeft={"10px"}
            paddingRight={"10px"}
            paddingTop={"10px"}
          >
            {items.map((item, index) => (
              <Grid
                key={index}
                item
                xs={12}
                sm={6}
                md={3}
                // paddingTop={"0px !important"}
                // paddingBottom={"20px"}
              >
                <LibraryGridItem
                  key={index}
                  item={item}
                  allTags={tags}
                  selectedLanguage={selectedLanguage?.id}
                />
              </Grid>
            ))}
          </Grid>
        </InfiniteScroll>
      </div>
    </div>
  );
};
