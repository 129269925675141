import React, { HTMLProps, ReactNode } from "react";
import { Circle } from "../Circle/Circle";
import { GeneralUtils } from "@utils";
import { Creative } from "@appTypes";
import "./ShareButtons.scss";
import { useToastsManager } from "@hooks/use-toasts-manager.hook";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  RedditIcon,
  RedditShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  WhatsappIcon,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { Box, Button } from "@mui/material";
import {Analytics} from "@services";

type ShareButtonsProps = {
  creative: Creative;
  text?: string;
  enableCollectionsDropdown?: boolean;
  disableAddNote?: boolean;
  onDropdownStateChange?: (isOpen: boolean) => void;
} & HTMLProps<HTMLDivElement>;

type SocialMediaKind =
  | "linkedin"
  | "facebook"
  | "reddit"
  | "twitter"
  | "whatsapp"
  | "telegram";

const getSummaryPropForKind = (kind: SocialMediaKind) => {
  switch (kind) {
    case "facebook":
      // Deprecated
      return "quote";
    case "linkedin":
      return "summary";
    default:
      return "text";
  }
};

export const ShareButtons = ({
  creative,
  text = "",
  enableCollectionsDropdown = true,
  onDropdownStateChange,
  disableAddNote,
  ...htmlProps
}: ShareButtonsProps) => {
  const toastsManager = useToastsManager();

  const copyLink = async () => {
    Analytics.linkCopy(creative.shareUrl);
    await GeneralUtils.copyToClipboard(creative.shareUrl);
    toastsManager.success("Link Copied!");
  };

  const generateProps = (icon: ReactNode, kind: SocialMediaKind) => ({
    children: (
      <Circle
        className="share-buttons__button"
        width={60}
        backgroundColor={"white"}
      >
        {icon}
      </Circle>
    ),
    url: creative.shareUrl,
    [getSummaryPropForKind(kind)]: text,
  });

  return (
    <Box>
      <div
        {...htmlProps}
        className="share-buttons"
        onClick={(e) => e.stopPropagation()}
      >
        <FacebookShareButton
          {...generateProps(<FacebookIcon round={true} />, "facebook")}
        />
        <LinkedinShareButton
          {...generateProps(<LinkedinIcon round={true} />, "linkedin")}
        />
        <TwitterShareButton
          {...generateProps(<TwitterIcon round={true} />, "twitter")}
        />
        <RedditShareButton
          {...generateProps(<RedditIcon round={true} />, "reddit")}
        />
        <TelegramShareButton
          {...generateProps(<TelegramIcon round={true} />, "telegram")}
        />
        <WhatsappShareButton
          {...generateProps(<WhatsappIcon round={true} />, "whatsapp")}
        />
      </div>
      <hr />
      <Button variant="text" onClick={() => copyLink()}>
        Copy link
      </Button>
    </Box>
  );
};
