import React from "react";

const BannerImg = require(`../../assets/${process.env.REACT_APP_LAYOUT}/banner.jpg`);

type LogoProps = {
  size: number | string;
} & Omit<React.HTMLProps<HTMLDivElement>, "size">;

export const Banner = ({ size = 32, className, ...rest }: LogoProps) => {
  return (
    <div>
      <img alt="banner" width={size} height={size} src={BannerImg} />
    </div>
  );
};
