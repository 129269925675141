import {
  PaletteAugmentColorOptions,
  PaletteOptions,
} from "@mui/material/styles/createPalette";

declare module "@mui/material/styles/createPalette" {
  interface Palette {
    white: PaletteColorOptions;
    menu: { hover: string; selected: string };
    periods: { pre: string; post: string };
    items: { background: string };
    purple: { light: string };
  }

  interface PaletteOptions extends Palette {}
}

export const paletteTheme: PaletteOptions = {
  primary: { main: "#a80710", light: "#fff0f0" },
  secondary: { main: "#fff0f0", light: "#fff0f0", contrastText: "#47008F" },
  warning: { main: "#DB6460" },
  success: { main: "#65EAC2" },
  common: { black: "#333333", white: "#FFFFFF" },
  white: { main: "#FFFFFF", contrastText: "#787E8D" },
  grey: {
    50: "#e3e4e7",
    100: "#F8F8FB",
    200: "#787E8D",
    300: "#BBBEC6",
    400: "#F2F2F9",
    500: "rgba(242, 242, 249, 0.4)",
    600: "#EFEFF4",
    700: "#E5E5E5",
    800: "#C9C8DB",
    900: "#4F4F4F",
    A100: "#FCFCFF",
  },
  purple: {
    light: "#D1D1FF",
  },
  text: { primary: "#333333", secondary: "#64676A", disabled: "#BBBEC6" },
  divider: "#BBBEC6",
  background: { default: "#FFFFFF", paper: "transparent" },
  action: {
    hover: "#BB0166",
    selected: "#a80710",
    disabled: "#BBBEC6",
  },
  menu: {
    hover: "#E9E7FF",
    selected: "rgba(237, 237, 255, 0.6)",
  },
  periods: {
    pre: "#19B7E9",
    post: "#7823E4",
  },
  items: {
    background: "#F2F1FA",
  },
  augmentColor: (_options: PaletteAugmentColorOptions) => {
    return { light: "", main: "", dark: "", contrastText: "" };
  },
};
