import React from "react";
import "./Logo.scss";
import classNames from "classnames";
import { useIsMobile } from "@hooks";

const LogoDesktop =
  require(`../../assets/${process.env.REACT_APP_LAYOUT}/logo-desktop.svg`).default;
const LogoMobile = require(`../../assets/${process.env.REACT_APP_LAYOUT}/logo-mobile.svg`).default;

type LogoProps = {
  size: number | string;
} & Omit<React.HTMLProps<HTMLDivElement>, "size">;

export const Logo = ({ size = 64, className, ...rest }: LogoProps) => {
  const isMobile = useIsMobile();

  return (
    <div {...rest} className={classNames("logo", className)}>
      <img
        alt="logo"
        width={size}
        src={isMobile? LogoMobile : LogoDesktop}
      />
    </div>
  );
};
