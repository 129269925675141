import { AppDefaultTexts } from "../types";
import { db } from "./firebase";
import { collection, getDocs, query } from "firebase/firestore";

export const getAppDefaultTexts = async (): Promise<AppDefaultTexts> => {
  const q = query(collection(db, "app_default_texts"));
  const documentSnapshots = await getDocs(q);
  if (documentSnapshots.docs.length === 0) {
    return {};
  }
  const docData = documentSnapshots.docs[0].data();
  return {
    tabsDefaultHtmlSubtitle: docData["tabsDefaultHtmlSubtitle"],
  };
};
