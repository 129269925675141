import { ConceptType } from "@appTypes";
import React from "react";
import "./LibraryConceptTypeFilter.scss";
import { Tabs, Tab, Grid } from "@mui/material";
import { Banner, Logo } from "@components";
import classNames from "classnames";
import { useIsMobile } from "@hooks";
import { Analytics } from "@services";

type LibraryConceptTypeFilterProps = {
  value: string;
  onChange: (id: string) => void;

  values: ConceptType[];
};

const LibraryContentTypeFilter = (props: LibraryConceptTypeFilterProps) => {
  const { value, onChange, values } = props;
  const isMobile = useIsMobile();

  const selectedTabIndex = values.findIndex(({ id }) => id === value);

  return values.length > 1 ? (
    <Tabs
      TabScrollButtonProps={
        isMobile
          ? {
              style: {
                color: "white",
              },
            }
          : {}
      }
      TabIndicatorProps={{
        style: {
          display: "none",
        },
      }}
      className="filter__menu"
      value={selectedTabIndex > -1 ? selectedTabIndex : 0}
      onChange={(_, i) => {
        onChange(values[i].id);
      }}
      variant="scrollable"
      scrollButtons={isMobile ? true : "auto"}
      allowScrollButtonsMobile={true}
      aria-label="library content type filter"
    >
      {values.map((concept) => {
        const { id } = concept;
        const isSelected = id === value;

        return (
          <Tab
            key={id}
            label={
              <span
                className={classNames(
                  "filter__menu__item",
                  isSelected && "selected"
                )}
                key={id}
                onClick={() => {
                  Analytics.pageView(values[selectedTabIndex]?.title);
                  onChange(id);
                }}
              >
                {concept.title}
              </span>
            }
            sx={{ textTransform: "none", padding: 0 }}
          />
        );
      })}
    </Tabs>
  ) : (
    <></>
  );
};

export default LibraryContentTypeFilter;
