import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCg0sOez-WQ0h2iZ8_akoxCvFD1tmPoycw",
  authDomain: "mavens-swords-of-iron.firebaseapp.com",
  projectId: "mavens-swords-of-iron",
  storageBucket: "mavens-swords-of-iron.appspot.com",
  messagingSenderId: "949127510078",
  appId: "1:949127510078:web:39f3979e3b47edc860796f",
  measurementId: "G-3F8GSXSZVT",
};

const app = initializeApp(firebaseConfig);
const dbId = process.env.REACT_APP_FIRESTORE_ID || "";
export const db = getFirestore(app, dbId);
export const analytics = getAnalytics(app);
