import { createTheme } from "@mui/material/styles";
import { paletteTheme } from "./palette";

declare module "@mui/material" {
  interface Theme {
    header: {
      height: string;
    };
    elevation: {
      normal: number;
      subHeader: number;
      mainHeader: number;
      tooltip: number;
    };
    borders: {
      1: string;
      2: string;
    };
  }

  interface ThemeOptions {
    header: {
      height: string;
    };
    elevation: {
      normal: number;
      subHeader: number;
      mainHeader: number;
      tooltip: number;
    };
    borders: {
      1: string;
      2: string;
    };
  }

  // Unless we define some of our custom options as optional this empty interface is correct
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  // interface DeprecatedThemeOptions extends Theme {}
}

const baseTheme = createTheme({
  zIndex: { tooltip: 2000 },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1350,
      xl: 1960,
    },
  },
  borders: {
    1: `1px solid #e3e4e7`,
    2: `2px solid #BBBEC6`,
  },
  typography: {
    /** Use Nunito font-face defined in an imported css file for all text unless overridden */
    fontFamily: ["nunito-regular", "sans-serif"].join(","),
  },
  header: { height: "64px" },
  palette: paletteTheme,
  shape: { borderRadius: 4 },
  // TODO: Check for breaking shadow animation
  shadows: [
    "none",
    "0px 2px 14px rgba(148, 148, 148, 0.1)",
    "0px 2px 14px rgba(148, 148, 148, 0.2)",
    "0px 2px 14px rgba(148, 148, 148, 0.3)",
    "0px 2px 12px rgba(148, 148, 148, 0.15)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
  ],
  /** Should be used instead of the mui theme zIndex prop */
  elevation: { normal: 0, subHeader: 400, mainHeader: 500, tooltip: 1000 },
});

baseTheme.spacing(2);

export const theme = createTheme(baseTheme, {
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            backgroundColor: "#F8F8FB",
            width: "3px",
            height: "3px",
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: 4,
            backgroundColor: "rgba(187, 190, 198, 0.4)",
            opacity: 0.4,
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        rounded: true,
        root: {
          background: baseTheme.palette.common.white,
          boxShadow: baseTheme.shadows[2],
          borderRadius: baseTheme.shape.borderRadius,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: ({ theme, ownerState }: any) => ({
          fontSize: "12px",
          "&:hover": {
            backgroundColor: "white",
          },
        }),
      },
    },
    MuiButton: {
      root: {
        "&:hover": {
          backgroundColor: baseTheme.palette.primary.main,
        },
      },
    },
    MuiSelect: {
      variants: [
        {
          props: { size: "small" },
          style: {
            fontSize: "11px",
            "& .MuiSelect-select": {
              padding: "6.38px 14px",
            },
          },
        },
        {
          props: { size: "medium" },
          style: {
            fontSize: "12px",
            "& .MuiSelect-select": {
              padding: "9.38px 14px",
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          width: "100%",
          // fontWeight: "bold",
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: baseTheme.borders[1],
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            border: baseTheme.borders[1],
          },
          ".MuiOutlinedInput-notchedOutline": {
            border: baseTheme.borders[1],
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: "#eaeaea",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          maxWidth: "unset",
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          backgroundColor: "#ededf1",
          transform: "none",
        },
      },
    },
  },
});
