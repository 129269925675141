import { Creative, Tag } from "@appTypes";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "./LibraryGridItem.scss";
import { useInView } from "react-intersection-observer";
import { Button, Grid, useTheme } from "@mui/material";
import { CloudinaryUtils, GeneralUtils } from "@utils";
import ReactPlayer from "react-player/lazy";
import { useIsMobile } from "@hooks";
import LibraryItemShareModal from "../LibraryItemShareModal/LibraryItemShareModal";
import { useToastsManager } from "@hooks/use-toasts-manager.hook";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import styled from "@emotion/styled";
import CollapsibleText from "@components/CollapsibleText/CollapsibleText";
import { shuffle } from "lodash";
import EmbeddedSocialNetworkItem from "../EmbeddedSocialNetworkItem/EmbeddedSocialNetworkItem";
import checkIphone = GeneralUtils.checkIphone;
import { Analytics } from "@services";

const ShareIcon = require("../../../assets/share.svg").default;
const DownloadIcon = require("../../../assets/download.svg").default;

type LibraryGridItemProps = {
  item: Creative;
  selectedLanguage?: string;
  allTags: Tag[];
};

const StyledDot = styled("span", {
  shouldForwardProp: (prop) => prop !== "selected",
})<{ color: string; size: string }>(({ color, size }) => ({
  height: size,
  width: size,
  backgroundColor: color,
  borderRadius: "50%",
  display: "inline-block",
}));

function getShareData(
  shareUrl: string,
  title: string,
  files: File[],
  text: string
) {
  const shareData = { files };
  if (!checkIphone()) {
    Object.assign(shareData, {
      url: shareUrl,
      title: title,
      text: text,
    });
  }
  return shareData;
}

const LibraryGridItem = ({ item, selectedLanguage }: LibraryGridItemProps) => {
  const theme = useTheme();
  const toastsManager = useToastsManager();
  const { ref, inView } = useInView({
    threshold: 0.7,
  });
  const [isDownloading, setIsDownloading] = useState(false);
  const isMobile = useIsMobile();
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [selectedLanguageText, setSelectedLanguageText] = useState("");
  const [videoContainerWidth, setVideoContainerWidth] = useState(0);
  const [videoContainerHeight, setVideoContainerHeight] = useState(0);
  const videoContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (videoContainerRef) {
      const elementWidth = videoContainerRef.current?.offsetWidth;
      const elementHeight = videoContainerRef.current?.offsetHeight;
      if (elementWidth && elementHeight) {
        setVideoContainerWidth(elementWidth);
        setVideoContainerHeight(elementHeight);
      }
    }
  }, [videoContainerRef]);

  const handleDownload = async () => {
    setIsDownloading(true);
    await GeneralUtils.downloadFileToHtmlElement(item.id, item.urls[0]);
    Analytics.itemDownload(item);
    setIsDownloading(false);
  };

  const copyText = async (text: string, toastText?: string) => {
    await GeneralUtils.copyToClipboard(text);
    toastsManager.custom(toastText || "Post text copied to clipboard");
  };

  const selectedLanguageTexts = useMemo(() => {
    const filteredTextsForLang = item.postText
      .filter((post) => post.language === selectedLanguage)
      .map((post) => post.text);
    return filteredTextsForLang;
  }, [selectedLanguage, item]);

  useEffect(() => {
    if (selectedLanguageTexts?.length > 0) {
      setSelectedLanguageText(shuffle(selectedLanguageTexts)[0]);
    } else {
      setSelectedLanguageText("");
    }
  }, [selectedLanguageTexts, item]);

  useEffect(() => {
    (async () => {
      try {
        if (item.type === "embedded") {
          return;
        }
        if (item) {
          if (inView) {
            setIsDownloading(true);
            await GeneralUtils.downloadFiles(item.id, item.urls);
            setIsDownloading(false);
          }
        }
      } catch (e) {
        toastsManager.custom(`failed to download video`, false);
        console.log(`failed to download video`);
      }
    })();
  }, [item, inView]);

  const handleShare = useCallback(async () => {
    Analytics.itemShare(item);
    try {
      if (selectedLanguageText) {
        await copyText(selectedLanguageText);
      }
    } catch (e) {
      console.log(`hello Failed to copy text`, e);
    }
    if (!(navigator && navigator.canShare)) {
      console.log(`hello cant share native`);
      // open web share dialog
      setIsShareModalOpen(true);
      return;
    }

    setIsDownloading(true);

    try {
      const downloadedFiles = await GeneralUtils.downloadFiles(
        item.id,
        item.urls
      );
      if (downloadedFiles && downloadedFiles.length > 0) {
        const text = selectedLanguageText || item.title;
        const shareData: ShareData = getShareData(
          item.shareUrl,
          item.title,
          downloadedFiles,
          text
        );
        await navigator.share(shareData);
      } else {
        toastsManager.custom("Failed to download file", false);
        console.log(`hello Failed to download file`);
      }
    } catch (error: any) {
      console.log(`hello share error`, error);
      toastsManager.custom(error.message || error, false);
    }

    setIsDownloading(false);
  }, [item, selectedLanguageText, toastsManager]);

  const footerButtons = useMemo(() => {
    if (item.type === "embedded") {
      return (
        <Grid
          display="flex"
          justifyContent="center"
          paddingBottom={0}
          paddingTop={1}
          paddingRight={2}
        >
          <Button
            variant="contained"
            style={{
              textTransform: "none",
              borderRadius: "22px",
              minWidth: "266px",
            }}
            onClick={() => onEmbeddedClicked()}
          >
            Comment on this post
          </Button>
        </Grid>
      );
    }
    return <></>;
  }, [item]);

  const itemButtons = useMemo(() => {
    if (item.type === "embedded") {
      return <></>;
    }
    return (
      <>
        {isDownloading ? (
          <i
            style={{ padding: "5px", cursor: "pointer" }}
            className="fa-sharp fa-regular fa-spinner-third fa-spin"
          ></i>
        ) : (
          <>
            <img
              key={`${item.id}-share`}
              src={ShareIcon}
              alt="share"
              style={{
                marginRight: "10px",
                cursor: "pointer",
              }}
              onClick={() => {
                handleShare();
              }}
            />
            <img
              key={`${item.id}-download`}
              src={DownloadIcon}
              alt="share"
              style={{
                cursor: "pointer",
              }}
              onClick={() => handleDownload()}
            />
          </>
        )}
      </>
    );
  }, [item, isDownloading]);

  const onEmbeddedClicked = useCallback(async () => {
    await copyText(
      selectedLanguageText,
      `Comment copied to clipboard - redirecting to ${GeneralUtils.extractSocialNetworkName(
        item.urls[0]
      )}`
    );
    setTimeout(() => {
      window.open(
        GeneralUtils.extractEmbeddedReplyUrl(
          item.urls[0],
          selectedLanguageText
        ),
        "_blank"
      );
    }, 2000);
  }, [selectedLanguageText, item]);

  const itemContent = useMemo(() => {
    if (item.type === "video") {
      return (
        <div className="library-grid-item__video-container" ref={ref}>
          <div
            style={{ height: "100%", width: "100%" }}
            ref={videoContainerRef}
          >
            <ReactPlayer
              playsinline={true}
              playing={isMobile && inView}
              controls={true}
              style={{ backgroundColor: item.pinned ? "#FFF0F0" : "white" }}
              muted={false}
              height={"100%"}
              width={"100%"}
              url={CloudinaryUtils.resizeVideo(
                item.urls[0],
                videoContainerWidth,
                380
              )}
            />
          </div>
        </div>
      );
    }
    if (item.type === "image") {
      return (
        <div ref={ref}>
          <Carousel
            showThumbs={false}
            showArrows={!isMobile}
            showStatus={false}
            renderIndicator={(clickHandler, isSelected, index, label) => {
              if (item.urls.length === 1) {
                return <></>;
              }
              return (
                <StyledDot
                  onClick={clickHandler}
                  style={{ marginRight: "5px" }}
                  color={isSelected ? "#a80710" : "#facfcf"}
                  size={isSelected ? "11px" : "10px"}
                />
              );
            }}
          >
            {item.urls.map((itemUrl, index) => {
              return (
                <div key={index} style={{ height: "280px" }}>
                  <img alt={itemUrl} src={itemUrl} />
                </div>
              );
            })}
          </Carousel>
        </div>
      );
    }
    if (item.type === "embedded") {
      return (
        <EmbeddedSocialNetworkItem
          item={item}
          onClick={() => onEmbeddedClicked()}
        />
      );
    }
  }, [
    item,
    isMobile,
    inView,
    selectedLanguageText,
    videoContainerWidth,
    videoContainerHeight,
  ]);

  return (
    <div
      className="library-grid-item"
      style={{ height: isMobile ? undefined : "100%" }}
    >
      <LibraryItemShareModal
        isOpen={isShareModalOpen}
        onClose={() => setIsShareModalOpen(false)}
        creative={item}
      />
      {itemContent}

      <div
        className="library-grid-item__video-info"
        style={{ backgroundColor: item.pinned ? "#FFF0F0" : "white" }}
      >
        <div
          className="share_buttons"
          style={{ paddingTop: "5px", paddingBottom: "5px" }}
        >
          {itemButtons}
        </div>
        {selectedLanguageText && (
          <CollapsibleText
            onClickCopyText={
              item.type !== "embedded"
                ? () => {
                    copyText(selectedLanguageText);
                  }
                : undefined
            }
            onClickRegenrateTextButton={
              selectedLanguageTexts.length > 1
                ? () => {
                    const arrayWithoutCurrent = selectedLanguageTexts.filter(
                      (text) => text !== selectedLanguageText
                    );
                    setSelectedLanguageText(shuffle(arrayWithoutCurrent)[0]);
                  }
                : undefined
            }
            key={`${item.id}-${selectedLanguageText}`}
            text={selectedLanguageText}
            numberOfLines={2}
          />
        )}
        {footerButtons}
      </div>
    </div>
  );
};

export default LibraryGridItem;
