import { Creative } from "@appTypes";
import {
  FacebookEmbed,
  TwitterEmbed,
  TikTokEmbed,
} from "react-social-media-embed";
import React from "react";
import { InstagramLoadScript } from "./InstagramLoadScript";

interface EmbeddedSocialNetworkItemProps {
  item: Creative;
  onClick: () => void;
}

const EmbeddedSocialNetworkItem = (props: EmbeddedSocialNetworkItemProps) => {
  const embeddedSocialNetwork = (url: string) => {
    const props = {
      style: { pointerEvents: "none" },
    };
    const { hostname } = new URL(url);
    if (hostname.includes("tiktok")) {
      return <TikTokEmbed {...props} url={url} width="100%" />;
    }
    if (hostname.includes("instagram")) {
      return <InstagramLoadScript url={url} />;
      //   return <InstagramEmbed {...props} url={url} width="100%" debug={true} />;
    }
    if (hostname.includes("facebook")) {
      return <FacebookEmbed {...props} url={url} width="100%" />;
    }
    if (hostname.includes("twitter")) {
      return <TwitterEmbed {...props} url={url} width="100%" />;
    }
  };
  return (
    <div
      style={{ display: "flex", justifyContent: "center" }}
      onClick={async () => props.onClick()}
    >
      {embeddedSocialNetwork(props.item.urls[0])}
    </div>
  );
};

export default EmbeddedSocialNetworkItem;
