import React from "react";
import "./AppLoader.scss";
import { Logo } from "../Logo/Logo";

export const AppLoader = () => {
  return (
    <div className="app-loader">
      <div className="logo-wrapper">
        <Logo
          size={100}
          style={{
            zIndex: 200,
            position: "relative",
            display: "block",
          }}
        />
      </div>
    </div>
  );
};
