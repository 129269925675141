import { Button, Grid } from "@mui/material";
import React from "react";
const InstagramIcon = require("../../assets/instagramLogo.svg").default;
const FacebookIcon = require("../../assets/facebookLogo.svg").default;

interface MomTooButtonProps {
  color?: string;
}

export const MomTooButton = ({ color }: MomTooButtonProps) => {
  return (
    <Button
      size="small"
      variant="outlined"
      style={{
        color: color || undefined,
        textTransform: "none",
        marginRight: "10px",
      }}
      color={color ? "inherit" : "primary"}
      // variant="outlined"
      onClick={() => {
        window.open("https://momtoo.net", "_blank");
      }}
    >
      MomToo
    </Button>
  );
};

interface SocialLinksProps {
  instagramLink?: string;
  facebookLink?: string;
  style?: React.CSSProperties;
}

const SocialLinks = ({
  instagramLink,
  facebookLink,
  style,
}: SocialLinksProps) => {
  const onLinkClicked = (url: string) => {
    window.open(url);
  };
  return (
    <Grid style={style} display={"flex"}>
      <MomTooButton color="white" />
      {instagramLink && (
        <img
          style={{ padding: "6px", cursor: "pointer" }}
          alt="instagram logo"
          src={InstagramIcon}
          onClick={() => {
            onLinkClicked(instagramLink);
          }}
        />
      )}
      {facebookLink && (
        <img
          style={{ padding: "6px", cursor: "pointer" }}
          alt="facebook logo"
          src={FacebookIcon}
          onClick={() => {
            onLinkClicked(facebookLink);
          }}
        />
      )}
    </Grid>
  );
};

export default SocialLinks;
