import { logEvent } from "firebase/analytics";
import { analytics } from "./firebase";
import {Creative} from "@appTypes";

export class Analytics {
  static pageView(pageTitle: string) {
    logEvent(analytics, "page_view", { pageTitle });
  }

  static itemDownload(item: Creative) {
    logEvent(analytics, "item_download", item );
  }

  static itemShare(item: Creative) {
    logEvent(analytics, "item_share", item );
  }
  static linkCopy(link: string) {
    logEvent(analytics, "link_copy", { link });
  }
}
