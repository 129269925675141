import * as React from "react";
import Button from "@mui/material/Button";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useEffect, useState } from "react";
import { ConceptType } from "@appTypes";
import styled from "@emotion/styled";

const BlurryDialog = styled(Dialog)<DialogProps>(({ theme }) => ({
  backdropFilter: "blur(5px)",
}));

interface SensitiveContentDialogProps {
  conceptType?: ConceptType;
  goBack: () => void;
  disableClose?: boolean;
}

export const SensitiveContentDialog = ({
  conceptType,
  goBack,
  disableClose,
}: SensitiveContentDialogProps) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    localStorage.setItem(`sensitive_content_${conceptType!.id}`, "true");
    setOpen(false);
  };

  const handleClose = () => {
    if (disableClose) return;
    localStorage.setItem(`sensitive_content_${conceptType!.id}`, "false");
    goBack();
    setOpen(false);
  };

  useEffect(() => {
    if (!conceptType?.isSensitive) {
      setOpen(false);
      return;
    }
    const isApprovedConceptType = localStorage.getItem(
      `sensitive_content_${conceptType.id}`
    );
    if (!isApprovedConceptType || isApprovedConceptType === "false") {
      setOpen(true);
    }
  }, [conceptType?.id]);

  return (
    <div>
      <BlurryDialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Sensitive Content"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This tab contains sensitive content which some people may find
            disturbing.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {!disableClose && <Button onClick={handleClose}>Disagree</Button>}
          <Button onClick={handleClickOpen} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </BlurryDialog>
    </div>
  );
};

export default SensitiveContentDialog;
